import React from "react";
import { productos } from "../../api/productos";
import { Product } from "../../interface/producto.interface";
import { useParams } from "react-router-dom";
import { Container, Grid, Paper, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
//import DOMPurify from "dompurify";
import ReactHtmlParser from "react-html-parser";

export const ProductosDetallePage: React.FC<{}> = () => {
  const { slug } = useParams();
  const [product, setProduct] = React.useState<Product>({} as Product);
  const base_img = "https://shoppingdemascotas.com.ar/";

  React.useEffect(() => {
    productos
      .getProductWithSlug(slug)
      .then((r) => {
        debugger;
        console.log(r.data);
        setProduct(r.data);
        console.log(product);
      })
      .catch((e) => {
        console.error(e.message);
      });
  }, [slug]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{slug}</title>
        <link rel="canonical" href="https://shoppingdemacotas.com.ar" />
        <meta name="description" content={product?.nombre} />
      </Helmet>
      <Container  sx={{ pt: "2em" }}>
        <Paper elevation={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography textAlign='center' component="h5" variant="h5" mr={2} ml={2}>
                {product?.nombre}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ textAlign: "center", verticalAlign: "middle" }}
            >
              <Typography component="h6" variant="h6" color="#7FFF00" mt={2}>
                Precio: $ {product?.precio}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} sx={{ textAlign: "center", verticalAlign: "middle" }}>
              <img
                srcSet={`${base_img}${product.img_url}?fit=w640&crop&auto=format&dpr=2 2x`}
                src={`${base_img}${product?.img_url}?fit=w640&crop&auto=format`}
                alt={product?.nombre}
                loading="lazy"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6} pl={2} pr={2} sx={{ textAlign: "center", verticalAlign: "middle" }}>
              <p>{ReactHtmlParser(product?.descripcion)}</p>
            </Grid>
           
          </Grid>
        </Paper>
      </Container>
    </>
  );
};
//yarn add react-html-parser
