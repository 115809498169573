import React from "react";
import { Route, Routes } from "react-router-dom";
import {HomePage} from "./pages/home";
import {LoginPage} from "./pages/login";
import { RouterLayout } from "./common/RouterLayout";
import { RegisterPage } from "./pages/register";
import { ProductosPage } from "./pages/productos";
import { ProductosDetallePage } from "./pages/productos/detalle";
import { ProductoSearchPage } from "./pages/productos/search";
export const AppRouter: React.FC<{}> = () =>{

    return (
        <Routes>
            <Route path="/" element={<RouterLayout/>} >
            <Route path="/" element={<HomePage/>} />
            <Route path="/productos/search/:searchText/:page?" element={<ProductoSearchPage />} />
            <Route path="/productos/:slug/:page?" element={<ProductosPage/>} />
            <Route path="/producto/:slug" element={<ProductosDetallePage/>} />
            <Route path="/login" element={<LoginPage/>} />
            <Route path="/register" element={<RegisterPage/>} />
            </Route>
        </Routes>

    )


}