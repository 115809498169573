import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  IconButton,
} from "@mui/material";
import React from "react";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { Caracteristica } from "../interface/producto.interface";
import { InfoRounded } from "@mui/icons-material";

type CardProps = {
  slug: string;
  nombre: string;
  img_url: string;
  precio: number;
  caracteristicas: Caracteristica[];
};

export const CardProduct: React.FC<CardProps> = ({
  slug,
  nombre,
  img_url,
  precio,
  caracteristicas,
}) => {
  const base_img = "https://shoppingdemascotas.com.ar/";
  return (
    <Card sx={{ backgroundColor: "hsla(0, 0%, 25%)" }}>
      <CardMedia 
      component="img" 
      image={`${base_img}${img_url}`} 
      alt={slug} 
      style={{borderRadius:'5px'}}
      />
      
      <CardContent>
      <Typography
          gutterBottom
          textAlign="center"
          alignContent="center"
          variant="h6"
          height='150px'
          
        >
          {nombre}
        </Typography>
        <Typography
          textAlign="center"
          alignContent="center"
          variant="h4"
          fontSize="2em"
          fontStyle="italic"
          fontFamily="fantasy"
        >
          $ {precio}
        </Typography>
      </CardContent>
      <CardActions
        sx={{ display: "block", alignItems: "center", justifyContent: "center" }}
      >
        <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton href={`/producto/${slug}`} aria-label="share">
          <ShareIcon />
        </IconButton>
        <IconButton href={`/producto/${slug}`} aria-label="info">
          <InfoRounded />
        </IconButton>
        <IconButton aria-label="add-shopping-cart">
          <AddShoppingCartIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
};
