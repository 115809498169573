import {instance} from "./base.api"
//import { Product } from "../interface/producto.interface"


const endpoind="/categorias/top";

export const categorias={
    getAll:async () => {
        return await instance.get(endpoind)
        .then( (response) => {
            debugger
            console.log(response)
            return {
                status: response.status,
                data: response.data.categoria
            }
        }).catch((error) =>{
            console.log(error)
            return {
                status: error.status,
                data: error.response
            }
        })
    }
}