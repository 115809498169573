import * as yup from "yup"

export const LoginValidate = yup.object().shape({
    username: yup.string().min(6, 'El usuario debe tener 6 o mas caracteres').required("Debe ingresar un nombre de usuario").trim(),
    password: yup.string().trim().min(8, 'Password must be 8 characters long')
    .matches(/[0-9]/, 'Password requires a number')
    .matches(/[a-z]/, 'Password requires a lowercase letter')
    .matches(/[A-Z]/, 'Password requires an uppercase letter')
    .matches(/[^\w]/, 'Password requires a symbol'),
})

export const RegisterValidate = yup.object().shape({
    username: yup.string().required("Debe ingresar un nombre de usuario").trim(),
    password: yup.string().trim().min(8, 'Password must be 8 characters long')
    .matches(/[0-9]/, 'Password requires a number')
    .matches(/[a-z]/, 'Password requires a lowercase letter')
    .matches(/[A-Z]/, 'Password requires an uppercase letter')
    .matches(/[^\w]/, 'Password requires a symbol'),
  confirm: yup
    .string()
    .oneOf([yup.ref('password'),], 'Must match "password" field value'),
})