import React, {} from 'react';
import { Card, ImageList, ImageListItem, ImageListItemBar, Rating}  from '@mui/material' 

import { StarBorder } from '@mui/icons-material';
import { Product } from '../../interface/producto.interface';
export type ProductosGridProps = {
        data:  Product[]
}

const ProductosGrid: React.FC<ProductosGridProps>  = ({data}) => {
	const base_img = "https://shoppingdemascotas.com.ar";
	
	return (
		<ImageList 
			gap={12}
			sx={{
				mb:4,
				lg:3,
				gridTemplateColumns:
				'repeat(auto-fill,minmax(280px,1fr))!important'
			}}>
              {		
			  data.map((prod,index)=>
			  <Card>
              <ImageListItem 
			  key={index} 
			  component="a" 
			  href={`/producto/${prod?.slug}`}
			  sx={{height:'100% important'}}
			  >
				<ImageListItemBar
							sx={{background:'linear-gradient(to botton , rgba(0,0,0,0.7)0%, rgba(0,0,0,0.3)70%, rgba(0,0,0,0)100%)'}}
							title={prod?.nombre}
							position='top'
							/>
                  <img
                   srcSet={`${base_img}${prod?.img_url}`}
                   src={`${base_img}${prod?.img_url}?fit=crop&auto=format`}
                   alt={prod?.slug}
                   loading="lazy"
                   />
                   <ImageListItemBar
								title={`$ ${prod?.precio}`}
								actionIcon={
									<Rating
										sx={{color:'rgba(0,0,0,0.8)',mr:'5px'}}
									name='categoria-rating'
									defaultValue={3.5}
									precision={0.5}
									emptyIcon={<StarBorder sx={{color:'rgba(255,255,255,0.8)'}} />}
									/>
								}
						/>
              </ImageListItem>
			  </Card>
              
              )}
              </ImageList>
	);
	
};

export default ProductosGrid;
