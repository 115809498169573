import axios from 'axios'
//https://api.shoppingdemascotas.com.ar/api/alimentos-perros-y-gatos/pedigree-adulto-carne-pollo-y-cereales-21-kg
const BASE_URL="https://api.shoppingdemascotas.com.ar/api/"
interface Params {
    baseUrl: string
    headers : any
    method: string
}


export const instance = axios.create({
    baseURL: BASE_URL,

})