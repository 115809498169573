import React, {ChangeEvent, useState, KeyboardEvent} from 'react';
import AppBar from '@mui/material/AppBar';
import {styled, alpha} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
//import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, InputBase, Avatar } from '@mui/material';
import Divider from '@mui/material/Divider';
import MailIcon from '@mui/icons-material/Mail';
import AccountBox from '@mui/icons-material/AccountBox';

import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import { categorias } from '../api/categorias';
import { categoria } from '../interface/categoria.interface';
import { Link, useNavigate } from 'react-router-dom';
import logo57 from '../assets/logo32x32.png'
//import { Key } from '@mui/icons-material';
// const NavUnlisted = styled.ul`
//   a {
//     text-decoration: none;
//   }
//   li {
//     color: red;
//     margin: 0 0.8rem;
//     font-size: 1.3rem;
//     position: relative;
//     list-style: none;
//   }

//   .active {
//     color: white;
//     border-bottom: 2px solid black;
//   }
// `;


const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'all',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));


export const NavBar: React.FC<{}>=()=>{
  const [open, setOpen] = useState(false)
  const [cats, setCats] = useState<categoria[]>([]);
  const [inputSearch, setInputSearch] = useState<string>("");
  const navigate = useNavigate();
     

  //e:SyntheticEvent para no renegar con el parametro
  const handleChange=(e: ChangeEvent<HTMLInputElement|HTMLTextAreaElement>)=>{
    e.preventDefault();
    setInputSearch(e.target.value)
  }

  const handleSearch = (e:KeyboardEvent<HTMLInputElement|HTMLTextAreaElement>) => {
    if(e.key==="Enter"){
      console.log("texto a buscar "+ inputSearch)
      navigate(`/productos/search/${inputSearch}`,{replace:false});
    }    
    
  }


    React.useEffect(() => {
      let load = async () => {
        let r = await categorias.getAll();
        if (r.status === 200) {
          setCats(r.data);
        }
      };
      load();
    }, []);
  
  const NavLink =[
    {
      name:"Contacto",
      patch:"/",
      icon:<MailIcon/>
    },
    {
      name:"Conozcanos",
      patch:"/",
      icon:<BusinessCenterIcon/>
    },
    {
      name:"Login",
      patch:"/login",
      icon:<AccountBox />    
    },
    ];

  
  
  const DrawerList = (
        <>    
      <List>
        {cats.map((cat) => (
          <ListItem component="a" key={cat?.id} disablePadding>
            <ListItemButton component="a" href={`/productos/${cat?.slug}`}>
              {/* <ListItemIcon>
                <InboxIcon />
              </ListItemIcon> */}
              <ListItemText primary={cat?.nombre} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      </>
  );

    return (
      <>
      <Box sx={{ flexGrow: 1 }}>
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
            onClick={()=> setOpen(!open)}
          >
            <MenuIcon />
            
          </IconButton>
          
          <Box sx={{flexGrow: 1,pr:1, display: { xs: 'block', sm: 'block' } }}>
          <Link to="/">
          <Avatar
            alt="Shopping de Mascotas"
            src={logo57}
            sx={{ width: 32, height: 32}}
          />
          </Link>
          </Box>
          

          
                
          
            
          <Box sx={{display:{xs:"none", sm:"block"}}}>
          {NavLink.map((item)=>
            <Button
            key={item.name}
            component="a"
            href={item.patch}
            >
              {item.icon}
              {item.name}
            </Button>
          
          )
          }
          </Box>
          <Search >
            <SearchIconWrapper>
              <IconButton>
                <SearchIcon />
              </IconButton>
              
            </SearchIconWrapper>
            <StyledInputBase
              key="search"
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
              type='text'
              onChange={(e)=>handleChange(e)}
              value={inputSearch}
              onKeyUp={(e)=>handleSearch(e)}
            />
          </Search>
        </Toolbar>
      </AppBar>
    </Box>
    <Drawer open={open} onClose={()=>setOpen(false)}>
    <Box sx={{ width: 250 }} role="presentation" onClick={()=>setOpen(false)}>
    <List sx={{display:{xs:"block", sm:"none"}}}>
        {NavLink.map((item) =>
          <ListItem key={item.name} disablePadding>
          <ListItemButton component="a" href={item.patch}>
            <ListItemIcon>
              {item.icon}
            </ListItemIcon>
            <ListItemText>
              {item.name}
            </ListItemText>
          </ListItemButton>
        </ListItem>
        )
      }
      </List>
      <Divider/>
        {DrawerList}
      </Box>
      </Drawer>
   </>
    );

}