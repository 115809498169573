import {instance} from "./base.api"
import { Product } from "../interface/producto.interface"
import { ProductsList } from "../interface/productList.interface"
import axios from 'axios'
const endpoind= "producto-por-categoria/"

const headers = {
    //Authorization: `Bearer ${yourAccessToken}`,
    'Content-Type': 'application/json',
  };


export const productos = {
    getByCategoria:async (categoria:string|undefined, page?:string|undefined) => {
        return await instance.get(
            `${endpoind}${categoria}`, { params: {page},}
        )
        .then( (response) => {
            debugger
            console.log(response)
            return {
                status: response.status,
                data: response.data
            }
        }).catch((error) =>{
            console.log(error)
            return {
                status: error.status,
                data: error.response
            }
        })
    //function(categoria:string, page?:number){
        //return instance.get(`${endpoind}${categoria}`, { params: {page}
    //})
    },
    getProductWithSlug:function(slug:string|undefined){
        return instance.get<Product>(`/producto/${slug}`,{headers})
    },
    // getProductWithSlug: async(slug: string|undefined) => {
    //     try {
    //       const response = await instance.get<Product[]>(`/producto/${slug}`,{headers});
    //       return response.data;
    //     } catch (error) {
    //       console.error('getProductWithSlug', error);
    //     }
    // },
    getProductWithParameters: async (categoryId: number) => {
        try {
          const response = await instance.get<Product[]>('/products', {
            params: { categoryId },
          });
      
          console.log('response getProductWithParameters', response.data);
          return response.data;
        } catch (error) {
          console.error('getProductWithParameters', error);
        }
      }


}




    export const searchProducts = async(query:string|undefined, page?:number) =>{
    try{
        const response = await instance.get<ProductsList>(`/producto/productos_by_nombre/${query}?page=${page}`);
        debugger
        console.debug(response)
        return  {
            status: response.status,
            data: response.data
        }
    }catch(error){
        
        if(axios.isAxiosError(error)){
            console.log("Error de axios en search: ",error.message);
            //return error.message;
        }else{
            console.log("inesperado error: ", error);
            //return "A ocurrido un inesperado error"
        }
    }
}
