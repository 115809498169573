import { Button, Container } from '@mui/material'
import React from 'react'

export const RegisterPage: React.FC<{}>=()=>{
    return(
        <Container sx={{mt:9}} maxWidth="xl">
            <Button fullWidth variant="contained">
                Pagina de registro
            </Button>
        </Container>

    )

}