import { Container, LinearProgress} from '@mui/material'
import React,{useState} from 'react'
import { categoria } from '../../interface/categoria.interface';
import { categorias } from '../../api/categorias';
import { CategoriaList } from '../../common/CategoriaList';
//import { CategoriasImage } from '../../common/CategoriasImage';

export const HomePage: React.FC<{}>=()=>{
    const [cats, setCats] = useState<categoria[]>([]);
    const [Loading, setLoading] = useState(false);
  
     
    React.useEffect(() => {
      setLoading(true);
      let load = async () => {
        let r = await categorias.getAll();
        if (r.status === 200) {
          setCats(r.data);
          setLoading(false);
        }
      };
      load();
    }, []);
    
   
    return(
        <Container sx={{mt:9}} maxWidth="xl">  
            {
            Loading ? (
              <LinearProgress />
              ) : ( 
               <CategoriaList data={cats} />
            )
            }
            
        </Container>
    )

}