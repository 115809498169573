import { CssBaseline, ThemeProvider, createTheme,colors } from "@mui/material"
import React from "react"
type ThemeProp={
    children: JSX.Element
}

export enum ThemePalette {
    BG="#12181b",
    LIMA="#C8FA5F",
    FONT_GLOBAL="'JetBrains Mono', monospace",
    ERROR_MAIN="#44336",
    BG_ERROR_MAIN="rgba(244,67,54,0.1)",
    SUCCESS_MAIN="#66bb6a",
    BG_SUCCESS_MAIN = "rgba(102,187,106,0.1)"
}
const theme= createTheme({
    palette:{
        mode:"dark",
        background:{
            default:ThemePalette.BG
        },
        primary : {
            main: ThemePalette.LIMA
        }
    },
    typography:{
        fontFamily:ThemePalette.FONT_GLOBAL
    },
    components:{
        MuiButton:{
            defaultProps:{
                   style:{
                       textTransform:"none",
                       boxShadow:"none",
                       borderRadius:"0.5em"
                   } ,
            },

        },MuiAlert:{
            defaultProps:{
                style:{
                  borderRadius: "0.8em",
                  fontSize:"1em",  
                },
            },styleOverrides:{
                standardError:{
                    border: `1px solid ${ThemePalette.ERROR_MAIN}`,
                    background: ThemePalette.BG_ERROR_MAIN,
                },
                standardSuccess:{
                    border: `1px solid ${ThemePalette.SUCCESS_MAIN}`,
                    background: ThemePalette.BG_SUCCESS_MAIN,
                }
            }
        },MuiTextField:{
            defaultProps:{
                   style:{
                       textTransform:"none",
                       boxShadow:"-moz-initial",
                       borderRadius:"0.8em",
                       fontSize:"1em",
                   },
            },


        },
        MuiLink: {
            // change property defaults
            defaultProps: {
              underline: "hover"
            },
            // override CSS
            styleOverrides: {
              // Mui class
              root: {
                 textDecoration:"none",
                 fontWeight: 600,
                 color: colors.lime[800],
                 backgroundColor:colors.deepOrange[800]

              }
            }
          }

        

    }
});

export const ThemeConfig: React.FC<ThemeProp> =({children})=>{

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </ThemeProvider>

    )
}