import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useNotification } from "../../context/notification.context";
import { LoginValidate } from "../../utils/validateForm";


type LoginType ={
    username: string,
    password: string
};


export const LoginPage: React.FC<{}> = () => {
  //const {loader,callEndpoint} = useFetchAndLoad();   
  const {getError,getSuccess} = useNotification();
  
  const[loginData, setLoginData] = React.useState<LoginType>(
        {
          username:"",
          password:"",  
        }
    );

    // copia el valor de loginData y los trae desde los input por su name
    const dataLogin = (e:React.ChangeEvent<HTMLInputElement>) =>{
        setLoginData({...loginData, [e.target.name]: e.target.value})
    };
    
    const handleSubmit = (e: React.FormEvent<HTMLInputElement>)=> {
        e.preventDefault();
        LoginValidate.validate(loginData).then(()=>{
          getSuccess(JSON.stringify(loginData))
        }).catch((error)=>{
          getError(error.message)
        })
        
        console.log(loginData)

    }


    return (
    <Container sx={{ mt: 9 }} maxWidth="xs">
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: "100vh" }}
      >
        <Grid item>
          <Paper sx={{ padding: "1.2em", borderRadius: "0.5" }}>
            <Typography sx={{ mt: 1.5, mb: 1.5 }} variant="h5">Iniciar sesión</Typography>
            <Box component="form" onSubmit={handleSubmit}>
              {/* <Stack spacing={2}> */}
              <TextField
                name="username"
                margin="normal"
                type="text"
                fullWidth
                label="Email"
                sx={{ mt: 2, mb: 1.5 }}
                required
                onChange={dataLogin}
              />
              <TextField
                name="password"
                margin="normal"
                type="password"
                fullWidth
                label="Clave"
                sx={{ mt: 2, mb: 1.5 }}
                required
                autoComplete="currentPassword"
                onChange={dataLogin}
              />
              {/* </Stack> */}
              <Button
                fullWidth
                type="submit"
                variant="contained"
                sx={{ mt: 1.5, mb: 1.5 }}
              >
                Ingresar
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
