"use client";
import React from 'react';
import { categoria } from '../../interface/categoria.interface';
import { Card, ImageList, ImageListItem, ImageListItemBar} from '@mui/material';


export type CategoriaListProps = {
	data:categoria[]
}

const CategoriaList: React.FC<CategoriaListProps>  = ({data}) => {
	const base_img = "https://shoppingdemascotas.com.ar";

	return (		
			<ImageList
			gap={12}
			sx={{
				mb:4,
				gridTemplateColumns:
				'repeat(auto-fill,minmax(340px,1fr))!important'

			}}
			>
				{
				data.map(cat=>(
					<Card>
						<ImageListItem 
							key={cat.id} 
							component="a" 
							href={`/productos/${cat.slug}`}
							sx={{height:'100% important'}}
						>
							<ImageListItemBar
							sx={{background:'linear-gradient(to botton , rgba(0,0,0,0.7)0%, rgba(0,0,0,0.3)70%, rgba(0,0,0,0)100%)'}}
							title={cat.nombre}
							position='top'
							/>
								<img
								src={`${base_img}${cat.img_url}`}
								alt={cat.nombre}
								title={cat.nombre}
								loading='lazy'
								style={{cursor:'pointer'}}
								/>
							
							</ImageListItem>	

					</Card>
				)
				)

				
				
				}
			</ImageList>
	);
};

export default CategoriaList;
