import React, { useState } from "react";
import { useParams,useLocation } from "react-router-dom";
import { SearchProducts } from "../../common/SearchProducts";
//import InfoIcon from '@mui/icons-material/Info';

export const ProductoSearchPage: React.FC<{}> = () => {
  //const [products, setProducts] = useState<Product[]>([]);
  
  const {searchText} =  useParams()
  const {page} =  useParams()
  //const location = useLocation();  
  
  // React.useEffect(() => {
  //   setLoading(true);
  //     let load = async () => {
  //       let r = await searchProducts(searchText);
  //       debugger
  //       if(r?.status===200){
  //       console.log(r) 
  //       setProducts(r.data.data as Product[]);
  //       }
  //       }
  //       setLoading(false);
  //   load();

  // },[searchText]);

  return (
    <>
      <SearchProducts query={searchText}/>
    </>
  );
};
