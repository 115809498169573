import { Box, Container, Grid, LinearProgress, Pagination } from "@mui/material";
import React, { useEffect,useState } from "react";
import { ProductsList } from "../interface/productList.interface";
import { searchProducts } from "../api/productos";
import { CardProduct } from "./CardProduct";



type PaginateProps = {
    query?:string
} 

export const SearchProducts: React.FC<PaginateProps> = ({query})=>{
    const [Loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [products, setProducts] = useState<ProductsList>({    
        current_page:   1,
        data:           [],
        first_page_url: "",
        from:           1,
        last_page:      1,
        last_page_url:  "",
        next_page_url:  "",
        path:           "",
        per_page:       12,
        prev_page_url:  "",
        to:             1,
        total:          1,
    })
    
    const handleChangePage = (e:React.ChangeEvent<unknown>,value:number) => {
    e.preventDefault();
    setPage(value)   
    }

    useEffect(()=>{
        setLoading(true)
        
        const getData =  async ()=>{
        const response = await searchProducts(query,page)     
                if(response?.status === 200){
                    setProducts(response.data)
                }
                setLoading(false)
            }
            
        getData()
             
            
    },[query,page]);
    
    return (
        <>
        <Container maxWidth="lg">
        <h1>Listado de productos</h1>
        
        {Loading ? (
          <LinearProgress />
        ) : (
          <Grid container spacing={2}> 
          {products.data.map((prod,index) => (
            <Grid item xs={12} sm={6} md={3} key={prod.id}>
               <CardProduct key={index} nombre={prod.nombre}
               img_url={prod.img_url}
               slug={prod.slug}
               precio= {parseFloat(prod.precio)}
               caracteristicas={prod.caracteristicas}
                />
            </Grid>
          ))}
          </Grid>
        )}
        <Box justifyContent={"center"} 
        alignItems={"center"} 
        display={"flex"}
        sx={{
            margin:"20px 0px"
        }}
        >   
        <Pagination page={page}
            count={Math.ceil(products.total/products.per_page)}
            onChange={handleChangePage}
                        
        />
        </Box>
      </Container>

        
        </>
    )

}