import React, { useEffect, useState } from "react";
import { productos } from "../../api/productos";
//import { Product } from "../../interface/producto.interface";
import { Box, Container, LinearProgress, Pagination } from "@mui/material";
import { useNavigate, useParams} from "react-router-dom";
//import { ProductsByCategoria } from "../../common/ProductsByCategoria";
import { ProductosGrid } from "../../common/ProductosGrid";
import { ProductsList} from "../../interface/productList.interface";
//import InfoIcon from '@mui/icons-material/Info';

export const ProductosPage: React.FC<{}> = () => {
  const [Allproducts, setAllProducts] = useState<ProductsList>({    
    current_page:   1,
    data:           [],
    first_page_url: "",
    from:           1,
    last_page:      1,
    last_page_url:  "",
    next_page_url:  "",
    path:           "",
    per_page:       12,
    prev_page_url:  "",
    to:             1,
    total:          1,
});
  const [Loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {slug} = useParams();
  const {page} = useParams();

  
  const [pagina, setPagina] = useState<number>(Number(page?page:1));
	const handleChangePage = (e:React.ChangeEvent<unknown>,value:number) => {
		e.preventDefault();
		setPagina(value)
    navigate(`/productos/${slug}/${value}`,{replace:false});
		}
    
  const getData =  async ()=>{
    setLoading(true)
    const response = await productos.getByCategoria(slug , page)     
            if(response?.status === 200){
                setAllProducts(response.data)
                setLoading(false)
              }
    
  };


useEffect(()=>{
    getData();
},[slug,page]);
  
  return (
    <>
      <Container maxWidth="lg">
        {Loading ? (
          <LinearProgress />
        ) : (
          <>
          <ProductosGrid data={Allproducts.data} />   
          <Box justifyContent={"center"} 
        alignItems={"center"} 
        display={"flex"}
        sx={{
            margin:"20px 0px"
        }}
        >   
        <Pagination page={pagina}
            count={Math.ceil(Allproducts.total/Allproducts.per_page)}
            onChange={handleChangePage}            
        />
        </Box>
        </>
        )
        }
      </Container>
      
    </>
  );
};
